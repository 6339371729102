<template>
  <div class="staff height1">
    <!--      头部      -->
    <bread></bread>
    <!-- <div v-else class="iq-top-navbar">
            <el-page-header @back="goBack" content="下级账号">
            </el-page-header>
        </div> -->
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="登录名称/登录账号" clearable @keyup.enter.native="search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="UserData" stripe style="width: 100%" ref="multipleTable" height="calc(100% - 90px)" :header-cell-style="{ background: '#F5F7FC' }">
          <!-- <el-table-column type="selection" width="55">
                    </el-table-column> -->
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="userName" label="登录名称"> </el-table-column>
          <el-table-column prop="phone" label="登录账号" align="center"> </el-table-column>
          <el-table-column prop="staffNum" label="测试人数" align="center"> </el-table-column>
          <el-table-column prop="createTime" label="注册时间" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
                <span class="primary optionBtn" @click="toDetail(scope.row)">明细</span>
              <!-- <span class="primary optionBtn" @click="toDetail(scope.row)">查看健康自测记录</span> -->
              <!-- <span class="primary optionBtn" @click="toInquiryTestDetail(scope.row)">辨证论治记录</span> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="StaffTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Distributor } from '../../../components/HospitalDomain/Distributor'
import { SelfTest } from '../../../components/TestRecord/SelfTest'
export default {
  name: 'Staff',
  components: {
    bread,
  },
  data() {
    var distributor = new Distributor(this.TokenClient, this.Services.Authorization)
    var selfTest = new SelfTest(this.TokenClient, this.Services.Healthy)
    return {
      DistributorDomain: distributor,
      SelfTestDomain: selfTest,
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
      },
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      StaffTotal: 0,
      UserData: [],
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
    }
  },
  mounted() {
    this.InitialUser()
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: '/TestRecord/SelfTest',
        query: {
          id: item.id,
        },
      })
    //   this.$router.push({
    //     path: '/TestRecord/AnswerUserTest',
    //     query: {
    //       answerPhone: item.answerPhone,
    //     },
    //   })
    },
    toInquiryTestDetail(item) {
      this.$router.push({
        path: '/TestRecord/InquiryTest',
        query: {
          id: item.id,
        },
      })
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.InitialUser()
    },
    search() {
      this.PageIndex = 1
      this.InitialUser()
    },
    InitialUser() {
      var _this = this
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.DistributorDomain.DistributorList(
        item.startTime,
        item.endTime,
        _this.userInfo.organizationId,
        1,
        _this.PageIndex,
        item.keyWord,
        0,0,
        function (data) {
          _this.UserData = data.data.results
          let ids = []
          _this.UserData.forEach((item) => {
            ids.push(item.id)
          })
          _this.$nextTick(() => {
            _this.SelfTestDomain.BatchStaff(
              ids,
              (res) => {
                var Array = res.data
                _this.UserData.forEach((item) => {
                  ids.push(item.id)
                  let maps = Array.filter((i) => item.id == i.userId)
                  if (maps.length > 0) {
                    _this.$set(item, 'staffNum', maps[0].staffNum)
                  } else {
                    _this.$set(item, 'staffNum', 0)
                  }
                })
              },
              (error) => {
                console.log('请求失败!请刷新重试:', error)
              }
            )
          })
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.StaffTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
</style>
